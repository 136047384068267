<template>
  <div class="col-md-8 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>{{ title }}</h1>
        </div>
        <br>
        <form v-on:submit.prevent="saveItem()">
          <div class="form-group">
            <label>Código (*):</label>
            <input type="text" class="form-control" v-model="item.tid_cdgo" required autofocus>
          </div>
          <div class="form-group">
            <label>Nombre (*):</label>
            <input type="text" class="form-control" v-model="item.tid_nmbre" required>
          </div>
          <br />
          <div v-if="message">
            <p class="alert alert-warning" role="alert">{{ message }}</p>
          </div>
          <div class="row" align="center">
            <div class="col-4">
              <button type="submit" class="btn btn-primary" v-on:click="setClose(true)">{{ buttontext }}</button>
            </div>
            <div class="col-4">
              <button type="text" class="btn btn-success" v-on:click="setClose(false)">Guardar</button>
            </div>
            <div class="col-4">
              <button type="text" class="btn btn-secondary" v-on:click.prevent="cancel()">Cancelar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
//import toastr from 'toastr';

export default {
  data() {
    return {
      add: true, //Create a new document
      close: true, // Close the page and return to list
      title: "",
      item: {},
      message: "",
      buttontext: "",
    };
  },
  computed: {
    company() {
      return this.$store.state.company;
    },
    user() {
      return this.$store.state.user;
    },
    cancelButtonVisibility() {
      if (this.addInd) {
        return "visibility:hidden";
      } else {
        return "visibility:visible";
      }
    },
  },
  created: function() {
    this.add = this.$route.params.add;
    if (this.add) {
      this.title = "Crear un Tipo de identificación";
      this.buttontext = "Agregar";
    } else {
      this.title = "Editar un Tipo de identificación";
      this.buttontext = "Actualizar";
      this.getItem(this.$route.params.id);
    }
  },
  methods: {
    setClose(close) {
      this.close = close;
    },
    saveItem() {
      if (this.add) {
        this.item.cmp_id = String(this.$store.state.company);
        let uri = "/tpoids/add";
        //alert(this.item);
        this.axios
          .post(uri, this.item)
          .then((response) => {
            console.log(response);
            this.$router.replace({ name: "DisplayTpoid" });
            this.message = "Registro guardado";
          })
          .catch((err) => {
            console.log(err);
            this.message = "¡Error al crear el registro! " + err;
          });
      } else {
        let uri = "/tpoids/update/" + this.item._id;
        this.axios.post(uri, this.item).then((response) => {
          if (this.close) {
            this.$router.replace({ name: "DisplayTpoid" });
          }
          this.message = "Registro guardado";
        });
      }
    },
    getItem(id) {
      let uri = "/tpoids/edit/" + id;
      this.axios.get(uri).then((response) => {
        this.item = response.data;
      });
    },
    cancel() {
      this.$router.replace({ name: "DisplayTpoid" });
    },
  },
};
</script>

  <style>
.warning {
  color: #ff0000;
}
</style>
